import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { theme } from 'styles/styled';
import { useIsRtl } from 'hooks/useCurrentLocale';

import Text from 'components/Text';

const BuildDateText = styled(Text)`
  color: ${theme.palette.light};
  font-size: ${theme.fontSize.labelSmall};
  position: absolute;
  bottom: 0;
  right: ${theme.dimensions.spacingBase16}px;
`;

export default function BuildDate() {
  const isRtl = useIsRtl();

  const data = useStaticQuery<{
    currentBuildDate: {
      currentDate: string;
    };
  }>(graphql`
    query {
      currentBuildDate {
        currentDate
      }
    }
  `);

  if (process.env.SHOW_BUILD_DATE === 'y') {
    return (
      <BuildDateText isRtl={isRtl} as="small">
        Build date: {data.currentBuildDate.currentDate}
      </BuildDateText>
    );
  }
  return null;
}
