import styled from 'styled-components';
import { theme, ThemeInterface } from 'styles/styled';

interface TextProps {
  color?: 'dark' | 'light';
  weight?: keyof ThemeInterface['fontWeight'];
  isRtl: boolean;
}

const Text = styled.p<TextProps>`
  color: ${({ color = 'dark' }) => theme.palette[color]};
  font-size: ${theme.fontSize.copy};
  font-weight: ${({ weight = 'light' }) => theme.fontWeight[weight]};
  line-height: 28px;
  letter-spacing: ${theme.letterSpacings.normal};
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};

  @media ${theme.devices.medium} {
    font-size: ${theme.fontSize.base};
  }
`;

export const UppercasedText = styled(Text)`
  text-transform: uppercase;
`;

export default Text;
