import React from 'react';
import styled, { ThemeInterface } from 'styles/styled';

const Wrapper = styled.div<{
  showOnMobile: boolean;
  breakpoint?: keyof ThemeInterface['devices'];
}>`
  display: ${({ showOnMobile }) => (showOnMobile ? 'block' : 'none')};
  @media ${({ theme, breakpoint = 'medium' }) => theme.devices[breakpoint]} {
    display: ${({ showOnMobile }) => (showOnMobile ? 'none' : 'block')};
  }
`;

const Container = styled.div<Props>`
  position: absolute;
  background: transparent;
  display: block;
  z-index: ${({ zIndex }) => zIndex};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${({ position }) => position};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: ${({ height }) => height};
    padding-bottom: ${({ height }) => height};
    background: ${({ color }) => color};
  }
`;

const TopLeft = styled(Container)`
  &:after {
    clip-path: polygon(100% 0, 0 0, 0 100%);
  }
`;

const TopRight = styled(Container)`
  &:after {
    clip-path: polygon(100% 100%, 100% 0, 0 0);
  }
`;

const BottomRight = styled(Container)`
  &:after {
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
  }
`;

const BottomLeft = styled(Container)`
  &:after {
    clip-path: polygon(0 0, 0 100%, 100% 100%);
  }
`;

const Right = styled(Container)`
  &:after {
    clip-path: polygon(0 0, 0 100%, 100% 50%);
  }
`;

const Left = styled(Container)`
  &:after {
    clip-path: polygon(100% 0, 100% 100%, 0 50%);
  }
`;

const Custom = styled(Container)`
  &:after {
    clip-path: ${({ customClipPath }) => customClipPath};
  }
`;

export enum CornerTypes {
  'top-left', // Right triangle. Defined by 90° corner
  'top-right',
  'bottom-right',
  'bottom-left',
  'right', // Usual triangle.
  'left',
  'custom', // Custom path e.g. polygon(100% 0, 61% 100%, 60% 50%);
}

interface Props {
  width: number | string;
  height: number | string;
  color: string;
  showOnMobile?: boolean;
  zIndex?: number;
  customClipPath?: string;
  position?: {
    top?: number | string;
    left?: number | string;
    bottom?: number | string;
    right?: number | string;
  };
  cornerType?: CornerTypes;
  breakpoint?: keyof ThemeInterface['devices'];
}

const Triangle = ({
  width,
  height,
  color,
  cornerType,
  position,
  customClipPath,
  zIndex = 0,
  showOnMobile = false,
  breakpoint = 'medium',
}: Props) => {
  switch (cornerType) {
    case CornerTypes['top-left']:
      return (
        <Wrapper showOnMobile={showOnMobile} breakpoint={breakpoint}>
          <TopLeft
            width={width}
            height={height}
            color={color}
            position={position}
            zIndex={zIndex}
          />
        </Wrapper>
      );
    case CornerTypes['top-right']:
      return (
        <Wrapper showOnMobile={showOnMobile} breakpoint={breakpoint}>
          <TopRight
            width={width}
            height={height}
            color={color}
            position={position}
            zIndex={zIndex}
          />
        </Wrapper>
      );
    case CornerTypes['bottom-right']:
      return (
        <Wrapper showOnMobile={showOnMobile} breakpoint={breakpoint}>
          <BottomRight
            width={width}
            height={height}
            color={color}
            position={position}
            zIndex={zIndex}
          />
        </Wrapper>
      );
    case CornerTypes['bottom-left']:
      return (
        <Wrapper showOnMobile={showOnMobile} breakpoint={breakpoint}>
          <BottomLeft
            width={width}
            height={height}
            color={color}
            position={position}
            zIndex={zIndex}
          />
        </Wrapper>
      );
    case CornerTypes.right:
      return (
        <Wrapper showOnMobile={showOnMobile} breakpoint={breakpoint}>
          <Right
            width={width}
            height={height}
            color={color}
            position={position}
            zIndex={zIndex}
          />
        </Wrapper>
      );
    case CornerTypes.left:
      return (
        <Wrapper showOnMobile={showOnMobile} breakpoint={breakpoint}>
          <Left
            width={width}
            height={height}
            color={color}
            position={position}
            zIndex={zIndex}
          />
        </Wrapper>
      );
    case CornerTypes.custom:
      return (
        <Wrapper showOnMobile={showOnMobile} breakpoint={breakpoint}>
          <Custom
            width={width}
            height={height}
            color={color}
            position={position}
            customClipPath={customClipPath}
            zIndex={zIndex}
          />
        </Wrapper>
      );
    default:
      return (
        <Container
          width={width}
          height={height}
          color={color}
          position={position}
          zIndex={zIndex}
        />
      );
  }
};

export default Triangle;
