import React from 'react';
import styled from 'styled-components';
import { theme } from 'styles/styled';

const SocialMediaIconLink = ({
  children,
  ...props
}: JSX.IntrinsicElements['a']) => (
  <a rel="noopener noreferrer" target="blank" {...props}>
    {children}
  </a>
);

export default styled(SocialMediaIconLink)`
  display: inline-block;

  :hover {
    opacity: ${theme.ui.opacity('0.75')};
  }
`;
