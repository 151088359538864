import React from 'react';

const CustomTelegramIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}>
    <g fill="none">
      <circle cx="16" cy="16" r="16" fill="#FFF" />
      <path
        fill="#18A8FF"
        d="M7.792 15.193l15.076-6.118c.7-.266 1.31.18 1.084 1.293l.001-.001-2.567 12.727c-.19.903-.7 1.122-1.412.697l-3.91-3.032-1.885 1.912c-.208.22-.384.404-.788.404l.277-4.187L20.913 12c.316-.292-.07-.457-.486-.166l-8.953 5.933-3.86-1.268c-.838-.28-.856-.881.178-1.306z"
      />
    </g>
  </svg>
);

export default CustomTelegramIcon;
