import React from 'react';
import { Helmet } from 'react-helmet';
import useCurrentLocale from 'hooks/useCurrentLocale';
import { localizeUrl, useLanguages } from 'settings/i18n';

type Meta = {
  name: string;
  content: string | undefined;
};

export interface SEOMeta {
  seo?: {
    metaDesc: string;
    metaKeywords: string;
    metaRobotsNofollow: string;
    metaRobotsNoindex: string;
    opengraphAuthor: string;
    opengraphDescription: string;
    opengraphModifiedTime: string;
    opengraphPublishedTime: string;
    opengraphPublisher: string;
    opengraphSiteName: string;
    opengraphTitle: string;
    opengraphType: string;
    opengraphUrl: string;
    opengraphImage: {
      localFile: {
        name: string;
        extension: string;
      };
      mediaDetails: {
        height: string;
        width: string;
      };
      mimeType: string;
    };
    title: string;
    twitterDescription: string;
    twitterTitle: string;
  };
}

interface SEOProps {
  lang?: string;
  meta?: Meta[];
  image?: string;
  url?: string;
  imageType?: string;
  metadata: SEOMeta;
}
const fallbackDesc =
  'Mining City provides quality infrastructure backed high-performance cloud computing services for cryptocurrency mining. Choose a plan to get started today! What are you waiting for? Together We Grow!';
const fallbackTitle = 'Mining City';

const defaultMeta: SEOMeta = {
  seo: {
    metaDesc: fallbackDesc,
    metaKeywords: fallbackTitle,
    metaRobotsNofollow: 'follow',
    metaRobotsNoindex: 'index',
    opengraphAuthor: 'mining city',
    opengraphDescription: fallbackDesc,
    opengraphModifiedTime: '',
    opengraphPublishedTime: '',
    opengraphPublisher: '',
    opengraphSiteName: fallbackTitle,
    opengraphTitle: fallbackTitle,
    opengraphType: 'website',
    opengraphUrl: 'https://miningcity.com/',
    opengraphImage: {
      localFile: {
        name: '',
        extension: '',
      },
      mimeType: '',
      mediaDetails: {
        height: '',
        width: '',
      },
    },
    title: fallbackTitle,
    twitterDescription: fallbackDesc,
    twitterTitle: fallbackTitle,
  },
};

const SEO = ({
  lang,
  meta = [],
  metadata = defaultMeta,
  image,
  imageType,
  url,
}: SEOProps) => {
  const {
    metaDesc,
    metaKeywords,
    opengraphAuthor,
    opengraphDescription,
    opengraphModifiedTime,
    opengraphPublishedTime,
    opengraphPublisher,
    opengraphSiteName,
    opengraphTitle,
    opengraphImage,
    twitterDescription,
    twitterTitle,
  } = metadata.seo || {};

  const languages = useLanguages();
  const locales = languages.map((language) => language.locale);

  const locale = useCurrentLocale();

  const BASE_URL = process.env.BASE_URL as string;

  const ogImage =
    image ||
    `${BASE_URL}/${opengraphImage?.localFile.name}.${opengraphImage?.localFile.extension}` ||
    'logo.svg';

  const ogImageType = opengraphImage?.mimeType || imageType || 'image/svg';

  const canonicalUrl = BASE_URL + localizeUrl(locale, url as string);

  /* eslint-disable prefer-template */
  const ogUrl = canonicalUrl.endsWith('/') ? canonicalUrl : canonicalUrl + '/';

  const xDefaultUrl = BASE_URL + localizeUrl('en', url as string);

  const xDefaultUrlWoTrailingSlash = xDefaultUrl.endsWith('/')
    ? xDefaultUrl.slice(0, -1)
    : xDefaultUrl;

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang: locale }}
      title={metadata.seo?.title}
      meta={[
        {
          name: 'description',
          content: metaDesc,
        },
        {
          name: 'robots',
          content:
            url === '/maintenance' ? 'noindex, nofollow' : `follow, index`,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:author',
          content: opengraphAuthor,
        },
        {
          property: 'og:url',
          content: ogUrl,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:site_name',
          content: opengraphSiteName,
        },
        {
          property: 'og:article:published_time',
          content: opengraphPublishedTime,
        },
        {
          property: 'og:article:modified_time',
          content: opengraphModifiedTime,
        },
        {
          property: 'og:article:author',
          content: opengraphPublisher,
        },
        {
          property: 'og:locale',
          content: lang,
        },
        {
          property: 'og:title',
          content: opengraphTitle,
        },
        {
          property: 'og:description',
          content: opengraphDescription,
        },
        { property: 'og:image', content: ogImage },
        {
          property: 'og:image:width',
          content: opengraphImage?.mediaDetails?.width,
        },
        {
          property: 'og:image:height',
          content: opengraphImage?.mediaDetails?.height,
        },
        { property: 'og:image:type', content: ogImageType },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: twitterTitle || opengraphTitle,
        },
        {
          name: 'twitter:description',
          content: twitterDescription || opengraphDescription,
        },
        {
          property: 'fb:app_id',
          content: process.env.FACEBOK_APP_ID,
        },
      ].concat(meta)}>
      {locales.map((hrefLang) => (
        <link
          key={hrefLang}
          rel="alternate"
          hrefLang={hrefLang}
          href={BASE_URL + localizeUrl(hrefLang, url as string)}
        />
      ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={xDefaultUrlWoTrailingSlash}
      />
    </Helmet>
  );
};

export default SEO;
