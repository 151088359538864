import styled, { theme } from 'styles/styled';
import { UppercasedText } from 'components/Text/Text';
import SocialMediaIconLink from 'components/Footer/SocialMediaIconLink';

export const Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.palette.primary};
  padding: 0 ${theme.dimensions.spacingBase16 * 2}px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

  max-width: 1360px;
  width: 100%;

  padding: 0;

  @media ${theme.devices.medium} {
    flex-direction: row;
    align-items: flex-start;
    padding: ${theme.dimensions.spacingBase16 * 2}px 0;
  }
`;

export const FooterMenu = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto ${theme.dimensions.spacingBase16 * 1.5}px auto;

  @media ${theme.devices.medium} {
    flex-direction: row;
    margin: 3px auto ${theme.dimensions.spacingBase16 * 2.25}px auto;
  }
`;

export const Separator = styled.div`
  height: 2px;
  background: ${theme.palette.light};
`;

export const MenuLinksSeparator = styled(Separator)`
  width: 23px;
  margin: 12px auto;

  @media ${theme.devices.medium} {
    transform: rotate(90deg);
    margin: ${theme.dimensions.spacingBase16 * 0.75}px
      ${theme.dimensions.spacingBase16 * 1.5}px
      ${theme.dimensions.spacingBase16 * 0.75}px 0;
  }

  @media ${theme.devices.large} {
    margin: ${theme.dimensions.spacingBase16 * 0.75}px
      ${theme.dimensions.spacingBase16 * 2.5}px
      ${theme.dimensions.spacingBase16 * 0.75}px 0;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto ${theme.dimensions.spacingBase16}px auto;

  flex-direction: column;

  @media ${theme.devices.medium} {
    width: auto;
    margin-bottom: ${theme.dimensions.spacingBase16 * 1.5}px;
  }

  @media ${theme.devices.large} {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }

  @media (min-width: 1360px) {
    align-items: flex-end;
  }
`;

export const IconsWrapper = styled.div`
  margin-bottom: ${theme.dimensions.spacingBase16}px;
  margin-left: auto;
  margin-right: auto;

  @media ${theme.devices.medium} {
    margin-bottom: 20px;
  }

  @media (min-width: 1360px) {
    margin-bottom: 0;
  }
`;

export const PrivacyPolicySeparator = styled(Separator)`
  display: none;

  @media ${theme.devices.large} {
    display: block;
    width: 58px;
    transform: rotate(90deg);
  }
`;

export const PolicyLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 8px;

  @media ${theme.devices.medium} {
    margin-top: 16px;
    flex-direction: row;
  }

  @media ${theme.devices.large} {
    margin-top: 12px;
  }

  @media (min-width: 1360px) {
    margin-top: 32px;
  }
`;

export const Copyright = styled(UppercasedText)`
  margin-top: ${theme.dimensions.spacingBase16}px;
  font-size: ${theme.fontSize.label};
  max-width: 425px;
  text-align: center;
  text-transform: none;
  font-weight: 300;

  @media ${theme.devices.medium} {
    white-space: nowrap;
    margin-top: 0;
    font-size: ${theme.fontSize.labelBig};
    text-align: left;
  }
`;

export const MenuLink = styled(UppercasedText)`
  margin-top: ${theme.dimensions.spacingBase16}px;
  margin-right: 0;
  font-size: ${theme.fontSize.label};
  color: ${theme.palette.light} !important;
  text-decoration: none !important;

  :hover {
    opacity: ${theme.ui.opacity('0.75')};
  }

  @media ${theme.devices.medium} {
    margin-top: 0;
    margin-right: ${theme.dimensions.spacingBase16 * 1.5}px;
    font-size: ${theme.fontSize.base};
  }

  @media ${theme.devices.large} {
    margin-right: ${theme.dimensions.spacingBase16 * 2.5}px;
  }
`;

export const SignInMenuLink = styled(MenuLink)`
  margin-top: 0;
`;

export const PolicyLink = styled(UppercasedText)`
  margin: 0 auto;
  font-size: ${theme.fontSize.label};
  white-space: nowrap;
  color: ${theme.palette.light} !important;
  text-decoration: none !important;

  & + & {
    margin-top: 16px;
  }

  :hover {
    opacity: ${theme.ui.opacity('0.75')};
  }

  @media ${theme.devices.medium} {
    margin-left: 0;
    margin-top: 0;
    margin-right: 24px;
    font-size: ${theme.fontSize.labelBig};
    text-transform: none;

    & + & {
      margin-top: 0;
    }
  }
`;

export const MinimalFooter = styled(Footer)`
  padding-top: 0;
`;

export const MinimalContainer = styled(Container)`
  padding-top: 0;
  padding-bottom: 6px;

  @media (min-width: 1285px) {
    padding-bottom: ${theme.dimensions.spacingBase16 * 3}px;
  }
`;

export const MinimalFooterLinks = styled(FooterLinks)`
  margin-left: auto;
  margin-top: ${theme.dimensions.spacingBase16 * 1.5}px;

  @media ${theme.devices.medium} {
    margin-top: ${theme.dimensions.spacingBase16 * 0.5}px;
  }
`;

export const MinimalCopyright = styled(Copyright)`
  margin-left: ${theme.dimensions.spacingBase16 * 0.75}px;
`;

export const CustomSocialMediaIconLink = styled(SocialMediaIconLink)`
  margin-left: 24px;
`;
