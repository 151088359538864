import React from 'react';

const CustomYoutubeIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" r="16" fill="#FFF" fillRule="nonzero" />
      <path
        fill="#28AAFF"
        d="M21.788 16.48l-9.256 4.483a.372.372 0 01-.532-.34v-9.245c0-.282.292-.464.54-.337l9.256 4.763a.38.38 0 01-.008.677z"
      />
    </g>
  </svg>
);

export default CustomYoutubeIcon;
